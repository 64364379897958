Modernizr.on('objectfit', (result: boolean): void => {
  if (result) return;

  import(/* webpackChunkName: "polyfillObjectFit" */ './polyfill-object-fit');
});
Modernizr.on('csspositionsticky', (result: boolean): void => {
  if (result) return;

  import(
    /* webpackChunkName: "polyfillPositionSticky" */ './polyfill-position-sticky'
  );
});
