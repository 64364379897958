/* eslint-disable @typescript-eslint/ban-ts-ignore */
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";

function initModal(): void {
  // @ts-ignore
  $("[data-mfp-src]").magnificPopup({
    type: "inline",
    closeBtnInside: false,
    showCloseBtn: false,
    mainClass: "mfp-fade",
    removalDelay: 300,
    fixedContentPos: true,
  });
}

$(document).on("click", "[data-mfp-close]", (): void => {
  // @ts-ignore
  $.magnificPopup.close();
});

initModal();
