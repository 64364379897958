import enterView from 'enter-view';
import { animateValue } from '../modules/animate-value';

function runAnimateValue(): void {
  const selector = document.querySelectorAll('.trust-section-item__value');

  if (selector.length > 0) {
    enterView({
      selector,
      once: true,
      offset: 0.1,
      enter(el: HTMLElement): void {
        const $this = $(el);

        $this.addClass('show');

        if ($this.data('no-transition') !== undefined) return;

        animateValue(el, null, null, 1440);
      },
    });
  }
}

runAnimateValue();
