import enterView from 'enter-view';
import { animateCSS } from '../modules/animate-css';

const upperFirstWord = (text: string): string => {
  const [first, ...rest] = text.split('');
  return `${first.toUpperCase()}${rest.join('')}`;
};

function runSimpleAnimate(): void {
  const selector = document.querySelectorAll('[data-fade-in]');

  if (selector.length <= 0) return;

  enterView({
    selector,
    once: true,
    offset: 0.1,
    enter(el: HTMLElement): void {
      const direction = $(el).data('fade-in');
      const animationName = !!direction
        ? `fadeIn${upperFirstWord(direction)}`
        : `fadeIn`;
      animateCSS(el, animationName, '');
    },
  });
}

function runIllustrationAnimate(): void {
  const selector = document.querySelectorAll(
    '.index-illustration__animate-trigger'
  );

  if (selector.length <= 0) return;

  enterView({
    selector,
    once: true,
    offset: 0.1,
    enter(el: HTMLElement): void {
      animateCSS(el, 'fadeIn', '');
    },
  });
}

runSimpleAnimate();
runIllustrationAnimate();
