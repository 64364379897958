export function animateValue(
  obj: HTMLElement,
  start = 0,
  end: number = null,
  duration = 1200
): void {
  if (!obj) return;

  // save starting text for later (and as a fallback text if JS not running and/or google)
  const textStarting = obj.innerHTML;

  // remove non-numeric from starting text if not specified
  end = end || parseInt(textStarting.replace(/\D/g, ''));

  let startTimestamp = null;
  const step = (timestamp: number): void => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    const value = Math.floor(progress * (end - start) + start);
    obj.innerHTML = textStarting.replace(/([0-9]+)/g, String(value));
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
