import "@glidejs/glide/dist/css/glide.core.min.css";
import Glide, {
  Controls,
  Breakpoints,
  Swipe,
  Autoplay,
} from "@glidejs/glide/dist/glide.modular.esm";
import enterView from "enter-view";
import { animateCSS } from "../modules/animate-css";

const MEDIA_CAROUSEL_SELECTOR = ".index-media-carousel";
const FEATURE_CAROUSEL_SELECTOR = ".index-feature-carousel";
const STICKY_CAROUSEL_SELECTOR = ".index-sticky-carousel";

const isFirstStep = (el: Node): boolean => $(el).data("step") === 0;
const getCurrentItem = (step: number, root: HTMLElement): HTMLElement =>
  $(`.glide__slide:eq(${step}) .index-sticky-item__content`, root)[0];

$(STICKY_CAROUSEL_SELECTOR).each((_index, element): void => {
  let prevItem;
  const slider = new Glide(STICKY_CAROUSEL_SELECTOR, {
    type: "slider",
    perView: 1,
    animationDuration: 0,
    gap: 0,
  });
  slider.on("run.before", (next: { steps: number }): void => {
    const nextItem = getCurrentItem(next.steps, element);
    prevItem = getCurrentItem(slider.index, element);
    animateCSS(nextItem, "fadeOutUp", "", "remove");
    animateCSS(prevItem, "fadeOutUp", "", "add");
  });
  slider.on("run.after", (): void => {
    const currentItem = getCurrentItem(slider.index, element);
    animateCSS(currentItem, "fadeInUp", "", "add");
    animateCSS(prevItem, "fadeInUp", "", "remove");
  });
  slider.mount({ Controls });
  enterView({
    selector: document.querySelectorAll(".index-scrolly__step"),
    offset: 0,
    enter: (el: Node): void => {
      if (isFirstStep(el)) return;
      slider.go(`=${$(el).data("step")}`);
    },
    exit: (el: Node): void => {
      if (isFirstStep(el)) return;
      slider.go(`=${$(el).data("step") - 1}`);
    },
  });
});
$(FEATURE_CAROUSEL_SELECTOR).each((): void => {
  const slider = new Glide(FEATURE_CAROUSEL_SELECTOR, {
    type: "slider",
    focusAt: "center",
    gap: 24,
    perView: 768 / 296,
    breakpoints: {
      599: {
        perView: 414 / 266,
      },
      413: {
        perView: 375 / 266,
      },
    },
  });
  slider.mount({ Swipe, Breakpoints, Controls });
});
$(MEDIA_CAROUSEL_SELECTOR).each((): void => {
  const slider = new Glide(MEDIA_CAROUSEL_SELECTOR, {
    type: "carousel",
    focusAt: "center",
    perView: 1420 / 650,
    gap: 20,
    breakpoints: {
      1279: {
        perView: 768 / 260,
      },
      767: {
        perView: 375 / 265,
      },
    },
  });
  slider.mount({ Controls, Breakpoints, Swipe, Autoplay });
  enterView({
    selector: MEDIA_CAROUSEL_SELECTOR,
    enter: () => slider.update({ autoplay: 1500 }),
    once: true,
  });
});

const VALID_NUMBER_COMMENT = ".valid-number-comments__carousel";
$(VALID_NUMBER_COMMENT).each((): void => {
  const slider = new Glide(VALID_NUMBER_COMMENT, {
    type: "carousel",
    focusAt: "center",
    perView: 3,
    gap: 24,
    breakpoints: {
      767: {
        perView: 375 / 284,
      },
    },
  });
  slider.mount({ Controls, Breakpoints, Swipe });
});

const REVIEW_CAROUSEL_SELECTOR = ".premium-review-carousel";
$(REVIEW_CAROUSEL_SELECTOR).each((): void => {
  const slider = new Glide(REVIEW_CAROUSEL_SELECTOR, {
    type: "carousel",
    focusAt: "center",
    perView: 1,
  });
  slider.mount({ Controls, Breakpoints, Swipe, Autoplay });
  enterView({
    selector: REVIEW_CAROUSEL_SELECTOR,
    enter: () => slider.update({ autoplay: 2500 }),
    once: true,
  });
});

const PREMIUM_PRICING_CAROUSEL_SELECTOR = ".premium-pricing-carousel";
$(PREMIUM_PRICING_CAROUSEL_SELECTOR).each((): void => {
  const popularIndex = parseInt($(PREMIUM_PRICING_CAROUSEL_SELECTOR).data( "popular-index" ))
  const slider = new Glide(PREMIUM_PRICING_CAROUSEL_SELECTOR, {
    type: "slider",
    perView: 3,
    gap: 8,
    startAt: 0,
    bound: true,
    breakpoints: {
      768: {
        focusAt: "center",
        perView: 1,
        startAt: popularIndex
      }
    }
  });
  slider.mount({ Controls, Breakpoints, Swipe });
  enterView({
    selector: PREMIUM_PRICING_CAROUSEL_SELECTOR,
    enter: () => {
      const srartAtIndex = 768 < innerWidth ? 0 : popularIndex
      slider.update({ startAt: srartAtIndex })
    },
    once: true,
  });
});
