/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "core-js/stable";
import "regenerator-runtime/runtime";
import "normalize.css";

import "../initializers/webfontloader";
import "../initializers/sns-share";
import "../initializers/clipboard";
import "../initializers/collapse";
import "../initializers/cookie-alert";
import "../initializers/webm-replacer";
import "../initializers/slide-show";
import "../initializers/conveyor";
import "../initializers/lazyload";
import "../initializers/scrollytelling-animate-value";
import "../initializers/scrollytelling-animation";
import "../initializers/world-map-glowing";
import "../initializers/modal";
import "../initializers/ie-fix";
import "../initializers/outline";
import "../initializers/huawei-badge";

import "../stylesheets/application.scss";

import "animate.css/source/_vars.css";
import "animate.css/source/_base.css";
import "animate.css/source/fading_entrances/fadeIn.css";
import "animate.css/source/fading_entrances/fadeInUp.css";
import "animate.css/source/fading_exits/fadeOutUp.css";
import "../stylesheets/application.scss";

if ($("#not-found-404").length > 0) {
  // @ts-expect-error
  import(/* webpackChunkName: "notFoundTx" */ "../initializers/not-found");
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context("../images", true);
// const imagePath = name => images(name, true);

// Uncomment to load svg sprite
const sprites = require.context("../icons", false);
sprites.keys().forEach(sprites);

$(document).on("click", "[data-href]", ({ currentTarget }): void => {
  window.location.href = $(currentTarget).data("href");
});

$(document).on("change", "[data-nav-selector]", ({ currentTarget }): void => {
  const $this = $(currentTarget);
  const dest = String($this.val());

  if (!dest) return;

  const method = $this.data("nav-selector");

  if (method === "replace") {
    window.location.href = dest;
    return;
  }

  window.location.href = dest;
});

$(document).on("click", ".flash-alert__toggle", ({ currentTarget }): void => {
  $(currentTarget)
    .parent()
    .fadeOut();
});

$(document).on("click", "[data-toggle-os]", ({ currentTarget }): void => {
  const $this = $(currentTarget);
  const os = $this.data("toggle-os");
  const $target = $this.closest("[data-os]");

  $target.attr("data-os", os);
});
