function calcDistance(node1, node2): number {
  return Math.sqrt(
    Math.pow(node1.x - node2.x, 2) + Math.pow(node1.y - node2.y, 2)
  );
}

$('.world-map-container__canvas').each(
  (_index, element: HTMLCanvasElement): void => {
    // how close next node must be to activate connection (in px)
    // shorter distance == better connection (line width)
    const SENSITIVITY = 100;
    // note that siblings limit is not 'accurate' as the node can actually have more connections than this value that's because the node accepts sibling nodes with no regard to their current connections this is acceptable because potential fix would not result in significant visual difference
    // more siblings == bigger node
    const SIBLINGS_LIMIT = 10;
    // default node margin
    const DENSITY = 50;
    // total number of nodes used (incremented after creation)
    let NODES_QTY = 0;
    // avoid nodes spreading
    const ANCHOR_LENGTH = 20;
    // highlight radius
    const MOUSE_RADIUS = 200;

    const circ = 2 * Math.PI;
    const canvas = element;
    let nodes = [];

    function resizeWindow(): void {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    resizeWindow();

    const mouse = {
      x: canvas.width / 2,
      y: canvas.height / 2,
    };
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    function Node(x, y): void {
      this.anchorX = x;
      this.anchorY = y;
      this.x = Math.random() * (x - (x - ANCHOR_LENGTH)) + (x - ANCHOR_LENGTH);
      this.y = Math.random() * (y - (y - ANCHOR_LENGTH)) + (y - ANCHOR_LENGTH);
      this.vx = Math.random() * 2 - 1;
      this.vy = Math.random() * 2 - 1;
      this.energy = Math.random() * 100;
      this.radius = Math.random();
      this.siblings = [];
      this.brightness = 0;
    }

    Node.prototype.drawNode = function(): void {
      const color = 'rgba(0, 195, 0, ' + this.brightness + ')';
      ctx.beginPath();
      ctx.arc(
        this.x,
        this.y,
        2 * this.radius + (2 * this.siblings.length) / SIBLINGS_LIMIT,
        0,
        circ
      );
      ctx.fillStyle = color;
      ctx.fill();
    };

    Node.prototype.drawConnections = function(): void {
      for (let i = 0; i < this.siblings.length; i++) {
        const color = 'rgba(0, 195, 0, ' + this.brightness + ')';
        ctx.beginPath();
        ctx.moveTo(this.x, this.y);
        ctx.lineTo(this.siblings[i].x, this.siblings[i].y);
        ctx.lineWidth = 1 - calcDistance(this, this.siblings[i]) / SENSITIVITY;
        ctx.strokeStyle = color;
        ctx.stroke();
      }
    };

    Node.prototype.moveNode = function(): void {
      this.energy -= 2;
      if (this.energy < 1) {
        this.energy = Math.random() * 100;
        if (this.x - this.anchorX < -ANCHOR_LENGTH) {
          this.vx = Math.random() * 2;
        } else if (this.x - this.anchorX > ANCHOR_LENGTH) {
          this.vx = Math.random() * -2;
        } else {
          this.vx = Math.random() * 4 - 2;
        }
        if (this.y - this.anchorY < -ANCHOR_LENGTH) {
          this.vy = Math.random() * 2;
        } else if (this.y - this.anchorY > ANCHOR_LENGTH) {
          this.vy = Math.random() * -2;
        } else {
          this.vy = Math.random() * 4 - 2;
        }
      }
      this.x += (this.vx * this.energy) / 100;
      this.y += (this.vy * this.energy) / 100;
    };

    function initNodes(): void {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      nodes = [];
      for (let i = DENSITY; i < canvas.width; i += DENSITY) {
        for (let j = DENSITY; j < canvas.height; j += DENSITY) {
          nodes.push(new Node(i, j));
          NODES_QTY++;
        }
      }
    }

    function findSiblings(): void {
      let node1, node2, distance;
      for (let i = 0; i < NODES_QTY; i++) {
        node1 = nodes[i];
        node1.siblings = [];
        for (let j = 0; j < NODES_QTY; j++) {
          node2 = nodes[j];
          if (node1 !== node2) {
            distance = calcDistance(node1, node2);
            if (distance < SENSITIVITY) {
              if (node1.siblings.length < SIBLINGS_LIMIT) {
                node1.siblings.push(node2);
              } else {
                let nodeSiblingDistance = 0;
                let maxDistance = 0;
                let s;
                for (let k = 0; k < SIBLINGS_LIMIT; k++) {
                  nodeSiblingDistance = calcDistance(node1, node1.siblings[k]);
                  if (nodeSiblingDistance > maxDistance) {
                    maxDistance = nodeSiblingDistance;
                    s = k;
                  }
                }
                if (distance < maxDistance) {
                  node1.siblings.splice(s, 1);
                  node1.siblings.push(node2);
                }
              }
            }
          }
        }
      }
    }

    function redrawScene(): void {
      resizeWindow();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      findSiblings();
      let i, node, distance;
      for (i = 0; i < NODES_QTY; i++) {
        node = nodes[i];
        distance = calcDistance(
          {
            x: mouse.x,
            y: mouse.y,
          },
          node
        );
        if (distance < MOUSE_RADIUS) {
          node.brightness = 1 - distance / MOUSE_RADIUS;
        } else {
          node.brightness = 0;
        }
      }
      for (i = 0; i < NODES_QTY; i++) {
        node = nodes[i];
        if (node.brightness) {
          node.drawNode();
          node.drawConnections();
        }
        node.moveNode();
      }
      requestAnimationFrame(redrawScene);
    }

    function mousemoveHandler(e): void {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }

    function initHandlers(): void {
      document.addEventListener('resize', resizeWindow, false);
      canvas.addEventListener('mousemove', mousemoveHandler, false);
    }

    initHandlers();
    initNodes();
    redrawScene();
  }
);
