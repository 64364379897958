const userAgent = navigator.userAgent || navigator.vendor || window.opera;

const detectHuawei = () => {
  const googlePlay = document.querySelectorAll('.google-play');
  const appGallery = document.querySelectorAll('.app-gallery');

  // TEST: 提供參數讓非華為手機也可以測試 icon 顯示
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsDevice = urlParams.get('device');

  if (/huawei/i.test(userAgent) || /LIO\-/.test(userAgent) || urlParamsDevice == 'huawei') {
    googlePlay.forEach(badge => {
      badge.classList.add('hidden');
    });
    appGallery.forEach(badge => {
      badge.style = '';
    });
    return;
  }

  appGallery.forEach(badge => {
    badge.classList.add('hidden');
  });
}

detectHuawei();
