import WebFont from 'webfontloader';

const initWebFontLoader = () => {
  WebFont.load({
    google: {
      families: ['Roboto:400,500,700', 'Noto Sans TC:400,500,700', 'Noto Sans JP:400,500,700', 'Noto Sans Thai:400,500,700', 'Noto Sans SC:400,500,700', 'Noto Sans KR:400, 500, 700']
    }
  });

  let wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';

  let script = document.getElementsByTagName('script')[0];
  script.parentNode.insertBefore(wf, script);
}

initWebFontLoader();