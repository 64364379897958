// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
const isiOSSafari_or_UIWebView = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent);
const isiOSChrome = navigator.userAgent.indexOf('CriOS') >= 0;

function replaceWebM() {
  var gif = $('.js-switch-gif').data('gif');
  $('.js-switch-gif').empty().append(
    "<img class='billboard__index-phone' data-fade-in=''" +
      "data-lazyload-src=\"" + gif + '" ' +
      "data-src=\"" + gif + '" ' +
      "src=\"" + gif + "\" />"
  );
}

if (isSafari || (isiOSSafari_or_UIWebView || isiOSChrome)) {
  replaceWebM();
}
