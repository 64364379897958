import * as ClipboardJS from 'clipboard';

const selector = '[data-clipboard-text]';
const classes = 'tooltipped tooltipped-s tooltipped-no-delay';

function clearTooltip(e: Event): void {
  $(e.currentTarget)
    .removeClass(classes)
    .removeAttr('aria-label');
}

function showTooltip(elem: Element, msg: string): void {
  $(elem)
    .addClass(classes)
    .attr('aria-label', msg);
}

function initClipboard(): void {
  $(selector).each((): void => {
    const clipboard = new ClipboardJS(selector);

    clipboard.on('success', e => {
      e.clearSelection();
      showTooltip(e.trigger, 'Copied!');
    });
  });
}

$(document).on('mouseleave', selector, clearTooltip);
$(document).on('blur', selector, clearTooltip);

initClipboard();
