function initLazyload(): void {
  $('[data-lazyload-src]').each((_index, el): void => {
    const $this = $(el);

    if ($this.hasClass('loaded')) return;

    const src = $this.data('lazyload-src');
    const img = new Image();
    img.src = src;
    img.onload = (): void => {
      $this.attr('src', src);
      $this.addClass('loaded');
    };
  });
}

initLazyload();
