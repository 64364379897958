import SmoothScroll from 'smooth-scroll';

$('.js-docout').each(function() {
  var dom = $(this)
  dom.hide();
  $(dom.data('body')).docout({
    target: dom.data('target')
  })
  var firstHeading = $(dom.data('body')).find('h1,h2,h3,h4,h5,h6').first();
  firstHeading.before(dom.children().first().remove());

  dom.remove();
  new SmoothScroll('a[href*="#"]', {
    header: '.main-header'
  })
});

var $outlineContainer = $('.blog-outline'),
    $outlineBody = $('.blog-outline__body'),
    $outlineHeader = $('.blog-outline__header');
    
// collapse toggle
$('#outline-toggle').on('click', function(event) {
  var bodyWidth = $outlineContainer.width();

  $outlineBody.toggle();
  $outlineHeader.css('width', bodyWidth);
  $outlineHeader.toggleClass('is-unset');
});