import * as Cookies from 'js-cookie';

const KEY = 'is_terms_accepted';

const toggleComponent = (): void => {
  $('#cookie-alert').toggleClass('hidden');
};

function initCookieAlert(): void {
  if (Cookies.get(KEY)) return;

  toggleComponent();
}

$(document).on('click', '.cookie-alert__accept', (): void => {
  Cookies.set(KEY, 'yes', {
    expires: Number(process.env.COOKIE_EXPIRES) || 30,
  });

  toggleComponent();
});

initCookieAlert();
