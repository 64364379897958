import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

function initConveyor() {
  $('.about-media-items').slick({
    accessibility: false,
    autoplay: true,
    draggable: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
    swipe: false,
    touchMove: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    centerMode: true,
    dots: false,
    centerPadding: '0',
    autoplaySpeed: 0,
    cssEase: 'linear',
    speed: 9e3,
    variableWidth: true,
  });
}

initConveyor();
