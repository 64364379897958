$(document).on('click', '[data-collapse]', (e): void => {
  const $this = $(e.currentTarget);
  const $container = $($this.data('collapse'));

  $this.closest($container).toggleClass('collapsed');
});

$(document).on('click', '[data-show]', (e): void => {
  const $this = $(e.currentTarget);
  const $container = $($this.data('show'));

  $this
    .closest($container)
    .addClass('transition')
    .toggleClass('show');
});

$(document).on('click', '[data-toggle-slide]', (e): void => {
  const $this = $(e.currentTarget);
  const $target = $($this.data('toggle-slide'));

  $this.toggleClass('collapsed');
  $target.slideToggle();
});
